const locale = {
  APPLICATIONS: 'تطبيقات',
  EXAMPLE: 'مثال',
  USER_MANAGEMENT: 'إدارةالمستخدم',
  BRANCHES: 'المواقع',
  ROOMS: 'غرف',
  DEVICES: 'الأجهزة'
};

export default locale;
