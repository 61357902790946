/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.


const creds = {
    wip: {
        Auth: {
            mandatorySignIn: true,
            region: 'us-east-1',
            userPoolId: 'us-east-1_g6beBq7eo',
            userPoolWebClientId: '5tvnbbo0dpc6cm0lnulp3rksl0',
            authenticationFlowType: 'USER_PASSWORD_AUTH',
            oauth: {},
        },
        aws_appsync_apiKey: 'da2-zzm774gf35dgjdmdtk6xivf53m',
        aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
        aws_appsync_region: 'us-east-1',
        aws_appsync_graphqlEndpoint:
            'https://txjexe33ufes3bgum7ae6aw57m.appsync-api.us-east-1.amazonaws.com/graphql',

    },
    beta: {
        Auth: {
            mandatorySignIn: true,
            region: 'us-east-1',
            userPoolId: 'us-east-1_HqXpTtn1x',
            userPoolWebClientId: '6hb798p4l2btsvbs5nsnl114mq',
            authenticationFlowType: 'USER_PASSWORD_AUTH',
            oauth: {},
        },
        aws_appsync_apiKey: 'da2-jqcfz4ayxfgm3ldw6ssttdonsa',
        aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
        aws_appsync_region: 'us-east-1',
        aws_appsync_graphqlEndpoint:
            'https://betaapi.meidisheet.com/graphql',

    }
}

export default creds[process.env.REACT_APP_ENV];
